@import "../../Foundation/_mediaqueries";
@import "../../Foundation/_variables";

.page-404 {
  padding: 150px 0 100px;

  @include pc-layout() {
    padding: 250px 0 150px;
  }

  .c-main-title {
    margin-bottom: 50px;

    .title-primary {
      font-size: 24px;

      @include pc-layout() {
        font-size: 32px;
      }
    }
  }

  .text {
    margin-bottom: 50px;
    font-size: 15px;
    text-align: center;
  }

  .c-button-primary {
    margin: 0 auto;
  }
}
