.page-404 {
  padding: 150px 0 100px;
}
@media only screen and (min-width: 992px) {
  .page-404 {
    padding: 250px 0 150px;
  }
}
.page-404 .c-main-title {
  margin-bottom: 50px;
}
.page-404 .c-main-title .title-primary {
  font-size: 24px;
}
@media only screen and (min-width: 992px) {
  .page-404 .c-main-title .title-primary {
    font-size: 32px;
  }
}
.page-404 .text {
  margin-bottom: 50px;
  font-size: 15px;
  text-align: center;
}
.page-404 .c-button-primary {
  margin: 0 auto;
}